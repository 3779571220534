import { AppPages } from '@app/app.pages';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UsersService } from '@app/services/users/users.service';
import { inject } from '@angular/core';

/*
 * NonAuthGuard is to used recdirect user if already logged in
 */

export const nonAuthGuard: CanActivateFn = async (): Promise<boolean | UrlTree> => {
  const router: Router = inject(Router);
  const usersService: UsersService = inject(UsersService);
  const isAuthenticated = await usersService.isAuthenticated();
  return isAuthenticated ? router.createUrlTree([AppPages.DashboardPage]) : true;
};
