import { AppConstants } from '@app/app.constants';
import { AppPages } from '@app/app.pages';
import { Router, UrlTree } from '@angular/router';
import { StorageService } from '@app/services/storage/storage.service';
import { UsersService } from '@app/services/users/users.service';
import { inject } from '@angular/core';

/*
 * AuthGuard is to used determine that user is logged in our not, if not we redirect to login page
 */

export const authGuard = async (): Promise<boolean | UrlTree> => {
  const router: Router = inject(Router);
  const storageService: StorageService = inject(StorageService);
  const usersService: UsersService = inject(UsersService);
  const isAuthenticated = await usersService.isAuthenticated();
  if (!isAuthenticated) {
    const skipIntro = await storageService.get(AppConstants.SKIP_INTRO);
    return skipIntro ? router.createUrlTree([AppPages.LoginPage]) : router.createUrlTree([AppPages.IntroPage]);
  }
  return true;
};
