import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppPages } from '@app/app.pages';
import { OobCodes } from '@enums/oob-codes.enum';
import { inject } from '@angular/core';

/*
 * firebaseLinksGuard is to used to redirect user to specific page based on firebase links
 */

export const firebaseLinksGuard = async (route: ActivatedRouteSnapshot): Promise<boolean> => {
  const router: Router = inject(Router);

  if (route.queryParams['oobCode']) {
    switch (route.queryParams['mode']) {
      case OobCodes.verifyEmail:
        router.navigate([AppPages.VerifyEmailPage], {
          queryParams: route.queryParams
        });
        break;
      case OobCodes.resetPassword:
        router.navigate([AppPages.SetPwPage], {
          queryParams: route.queryParams
        });
        break;
      default:
        router.navigate([AppPages.DashboardPage]);
    }
    return false;
  }

  return true;
};
