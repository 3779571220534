import { AppPages } from '@app/app.pages';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UsersService } from '@app/services/users/users.service';
import { inject } from '@angular/core';

export const setupGuard: CanActivateFn = async (): Promise<boolean | UrlTree> => {
  const router: Router = inject(Router);
  const usersService: UsersService = inject(UsersService);
  const isAuthenticated = await usersService.isAuthenticated();
  if (!isAuthenticated) {
    return router.createUrlTree([AppPages.LoginPage]);
  }
  return true;
};
