import { AppStateService } from '../app-state/app-state.service';
import { Capacitor } from '@capacitor/core';
import { GuardMode } from '@app/interfaces/guard-mode.interface';
import { GuardModeService } from '../guard-mode/guard-mode.service';
import { Injectable, inject } from '@angular/core';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';
import { RemoteConfig } from '@app/enums/remote-config.enum';
import { RemoteConfigService } from '../remote-config/remote-config.service';
import { catchError, combineLatest, filter, of, switchMap } from 'rxjs';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class DozePreventionService {
  private notificationId: number | null = null;
  private readonly appStateService: AppStateService = inject(AppStateService);
  private readonly guardModeService: GuardModeService = inject(GuardModeService);
  private readonly localNotificationsService: LocalNotificationsService = inject(LocalNotificationsService);
  private readonly remoteConfigService: RemoteConfigService = inject(RemoteConfigService);

  public initialize(): void {
    if (Capacitor.getPlatform() === 'android') {
      combineLatest([this.appStateService.appStateActive, this.guardModeService.isActive])
        .pipe(
          filter(([isAppActive, guardMode]: [boolean, GuardMode]) => !isAppActive && guardMode.isActive),
          switchMap(async () => await this.scheduleNotification()),
          catchError(error => {
            console.error('Error scheduling notification:', error);
            return of(null);
          })
        )
        .subscribe();
      combineLatest([this.appStateService.appStateActive, this.guardModeService.isActive])
        .pipe(
          filter(([isAppActive, guardMode]: [boolean, GuardMode]) => isAppActive || !guardMode.isActive),
          switchMap(async () => await this.cancelNotification()),
          catchError(error => {
            console.error('Error cancelling notification:', error);
            return of(null);
          })
        )
        .subscribe();
    }
  }

  private async scheduleNotification(): Promise<void> {
    const remoteDelay = await this.remoteConfigService.getNumber(RemoteConfig.DOZE_MODE_NOTIFICATION_DELAY);
    const delay: number =
      environment.production && remoteDelay !== null && remoteDelay !== undefined && remoteDelay > 0 ? remoteDelay : 30;
    console.log('🚀 ~ DozePreventionService ~ scheduleNotification ~ delay:', delay);
    const { notifications } = await this.localNotificationsService.scheduleNotification({
      title: 'labels_notifications_doze_mode_prevention_title',
      body: 'labels_notifications_doze_mode_prevention_description',
      date: new Date(Date.now() + delay * 60 * 1000),
      allowWhileIdle: true
    });
    this.notificationId = notifications.length ? notifications[0].id : null;
  }

  private async cancelNotification(): Promise<void> {
    if (this.notificationId !== null) {
      await this.localNotificationsService.cancelNotification(this.notificationId);
      this.notificationId = null;
    }
  }
}
