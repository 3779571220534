import { AppConstants } from '@app/app.constants';
import { AppPages } from '@app/app.pages';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { StorageService } from '@services/storage/storage.service';
import { inject } from '@angular/core';

/*
 * IntroGuard is used to determine if the user has already done the onboarding, if not stay on the intro page
 */

export const introGuard: CanActivateFn = async (): Promise<boolean | UrlTree> => {
  const router: Router = inject(Router);
  const skipIntro = await inject(StorageService).get(AppConstants.SKIP_INTRO);
  return skipIntro ? router.createUrlTree([AppPages.DashboardPage]) : true;
};
