import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AppEvents } from '@app/enums/app-events.enum';
import { AppLinkTypes } from '@app/enums/app-link-types.enum';
import { AppLinks } from '@app/interfaces/app-links.interface';
import { AppPages } from '@app/app.pages';
import { Injectable, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppLinksService {
  private readonly router: Router = inject(Router);
  private readonly zone: NgZone = inject(NgZone);
  /*
   * Used to initialize listeners for app links
   */
  public initialize(): void {
    App.addListener(AppEvents.appUrlOpen, (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const { params } = this.deconstructUrl(event.url);
        if (params && params['mode'] && params['oobCode']) {
          switch (params['mode']) {
            case AppLinkTypes.resetPassword:
              this.router.navigate([`${AppPages.SetPwPage}`], {
                queryParams: {
                  oobCode: params['oobCode']
                }
              });
              break;
          }
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  /*
   * Takes a URL and returns an object with the URL's hostname, path, and parameters.
   */
  private deconstructUrl(url: string): AppLinks {
    const parsedUrl = new URL(url);

    const hostname = parsedUrl.hostname;
    const path = parsedUrl.pathname;

    const searchParams = new URLSearchParams(parsedUrl.search);
    const params: { [key: string]: string } = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return {
      hostname,
      path,
      params
    };
  }
}
