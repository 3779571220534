import { AnalyticsService } from '@services/analytics/analytics.service';
import { AppLinksService } from '@services/app-links/app-links.service';
import { AppStateService } from '@services/app-state/app-state.service';
import { DeviceNotificationsService } from '@services/device-notifications/device-notifications.service';
import { DozePreventionService } from '@services/doze-prevention/doze-prevention.service';
import { IapService } from '../iap/iap.service';
import { Injectable, inject } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { LocalNotificationsService } from '@services/local-notifications/local-notifications.service';
import { LocationService } from '@services/location/location.service';
import { LocationUpdatesService } from '@services/location-updates/location-updates.service';
import { NetworkService } from '@services/network/network.service';
import { Platform } from '@ionic/angular/standalone';
import { RemoteConfigService } from '@services/remote-config/remote-config.service';
import { SessionRestorationService } from '@services/session-restoration/session-restoration.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBarService } from '@services/status-bar/status-bar.service';
import { WatchdogService } from '@services/watchdog/watchdog.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  private readonly analyticsService: AnalyticsService = inject(AnalyticsService);
  private readonly appLinksService: AppLinksService = inject(AppLinksService);
  private readonly appStateService: AppStateService = inject(AppStateService);
  private readonly deviceNotificationsService: DeviceNotificationsService = inject(DeviceNotificationsService);
  private readonly dozePreventionService: DozePreventionService = inject(DozePreventionService);
  private readonly iapService: IapService = inject(IapService);
  private readonly languageService: LanguageService = inject(LanguageService);
  private readonly localNotificationsService: LocalNotificationsService = inject(LocalNotificationsService);
  private readonly locationService: LocationService = inject(LocationService);
  private readonly locationUpdatesService: LocationUpdatesService = inject(LocationUpdatesService);
  private readonly networkService: NetworkService = inject(NetworkService);
  private readonly platform: Platform = inject(Platform);
  private readonly remoteConfigService: RemoteConfigService = inject(RemoteConfigService);
  private readonly sessionRestorationService: SessionRestorationService = inject(SessionRestorationService);
  private readonly statusBarService: StatusBarService = inject(StatusBarService);
  private readonly watchDogService: WatchdogService = inject(WatchdogService);

  constructor() {
    this.platform.ready().then(async () => {
      // init language service
      await this.languageService.initialize();
      // only when on capacitor
      if (this.platform.is('capacitor')) {
        // hide splash screen before default timeout
        await SplashScreen.hide();
        // set status bar style
        this.statusBarService.setStatusBarStyleDark();
      }
      // init app links service
      this.appLinksService.initialize();
      // init device notificationsService
      this.deviceNotificationsService.initialize();
      // init local notifications
      this.localNotificationsService.initialize();
      // init locationService
      await this.locationService.initialize();
      // init firebase remote config
      await this.remoteConfigService.initialize();
      // init subscription for network state
      this.networkService.initializeNetworkEvents();
      // init appStateService
      this.appStateService.initialize();
      // init analytics service
      await this.analyticsService.initialize();
      // init watchdog service
      this.watchDogService.initialize();
      // init session restoration service
      await this.sessionRestorationService.initialize();
      // init doze prevention service
      this.dozePreventionService.initialize();
      // init location updates service
      this.locationUpdatesService.initialize();
      // init iap service
      await this.iapService.initialize();
    });
  }
}
