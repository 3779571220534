import { AppStateService } from '../app-state/app-state.service';
import { GuardMode } from '@app/interfaces/guard-mode.interface';
import { GuardModeService } from '../guard-mode/guard-mode.service';
import { Injectable, NgZone, inject } from '@angular/core';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';
import {
  Subject,
  Subscription,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  pairwise,
  startWith,
  switchMap
} from 'rxjs';
import BackgroundGeolocation, {
  Subscription as BackgroundSubscription,
  Location
} from '@transistorsoft/capacitor-background-geolocation';

@Injectable({
  providedIn: 'root'
})
export class LocationUpdatesService {
  private backgroundSubscription: BackgroundSubscription | null | undefined;
  private locationSubscription: Subscription | null | undefined;
  private notificationId: number | null = null;
  private readonly appStateService: AppStateService = inject(AppStateService);
  private readonly guardModeService: GuardModeService = inject(GuardModeService);
  private readonly localNotificationsService: LocalNotificationsService = inject(LocalNotificationsService);
  private readonly locationUpdates$ = new Subject<Location>();
  private readonly ngZone: NgZone = inject(NgZone);

  public initialize(): void {
    combineLatest([this.appStateService.appStateActive, this.guardModeService.isActive])
      .pipe(
        filter(([isAppActive, guardMode]: [boolean, GuardMode]) => !isAppActive && guardMode.isActive),
        switchMap(async () => this.addListeners()),
        catchError(error => {
          console.error('Error adding listeners:', error);
          return of(null);
        })
      )
      .subscribe();
    combineLatest([this.appStateService.appStateActive, this.guardModeService.isActive])
      .pipe(
        filter(([isAppActive, guardMode]: [boolean, GuardMode]) => isAppActive || !guardMode.isActive),
        switchMap(async () => this.removeListeners())
      )
      .subscribe();
  }

  public addListeners(): void {
    this.backgroundSubscription = BackgroundGeolocation.onLocation((location: Location) => {
      this.ngZone.run(async () => {
        this.locationUpdates$.next(location);
      });
    });
    this.locationSubscription = this.locationUpdates$
      .pipe(
        map(location => location.is_moving),
        distinctUntilChanged(),
        startWith(null),
        pairwise(),
        debounceTime(5000)
      )
      .subscribe(async ([prevIsMoving, isMoving]: [boolean | null, boolean | null]) => {
        console.log(
          '🚀 ~ LocationUpdatesService ~ this.locationUpdates$.subscribe ~ isMoving:',
          prevIsMoving,
          isMoving
        );
        if (!isMoving && this.notificationId === null) {
          const { notifications } = await this.localNotificationsService.scheduleNotification({
            title: 'labels_notifications_session_not_moving_title',
            body: 'labels_notifications_session_not_moving_description',
            date: new Date(Date.now() + 5 * 60 * 1000)
          });
          console.log('🚀 ~ LocationUpdatesService ~ .subscribe ~ scheduleNotification:');
          this.notificationId = notifications.length ? notifications[0].id : null;
        } else if (prevIsMoving && !isMoving && this.notificationId !== null) {
          await this.localNotificationsService.cancelNotification(this.notificationId);
          console.log('🚀 ~ LocationUpdatesService ~ .subscribe ~ cancelNotification:');
          this.notificationId = null;
        }
      });
  }

  public removeListeners(): void {
    if (this.backgroundSubscription) {
      this.backgroundSubscription.remove();
      this.backgroundSubscription = null;
    }
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
      this.locationSubscription = null;
    }
  }
}
