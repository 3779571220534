import { AppPages } from '@app/app.pages';
import { BaseService } from '../base/base.service';
import { GuardModeService } from '../guard-mode/guard-mode.service';
import { Injectable, inject } from '@angular/core';
import { SessionStatus } from '@app/models/session';
import { SessionsService } from '../sessions/sessions.service';

@Injectable({
  providedIn: 'root'
})
export class SessionRestorationService extends BaseService {
  private readonly guardModeService: GuardModeService = inject(GuardModeService);
  private readonly sessionService: SessionsService = inject(SessionsService);

  public async initialize(): Promise<void> {
    const session = await this.sessionService.getCurrentSession();
    if (session && session.status === SessionStatus.InProgress) {
      await this.toastService.showToast({
        text: 'labels_sessions_continue'
      });
      const route = this.getRoute(AppPages.GuardInprogressPage, { id: session.id });
      await this.router.navigate([route]);
      this.guardModeService.enableGuardMode(session);
    }
  }
}
