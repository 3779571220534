import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from '@app/app.component';

import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { HttpClient, provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { RouteReuseStrategy, Router, provideRouter, withComponentInputBinding } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UsersService, usersServiceFactory } from '@app/services/users/users.service';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from './environments/environment';
import { initializeApp } from 'firebase/app';
import { routes } from '@app/app.routes';

if (!Capacitor.isNativePlatform()) {
  initializeApp(environment.firebase);
}

const translationLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);

if (environment.production) {
  Sentry.init({
    dsn: 'https://bc3c5dc676b74461a78f50ea96b34ef4@o59683.ingest.sentry.io/4504201550954496',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost'],
        routingInstrumentation: Sentry.routingInstrumentation
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3
  });
  enableProdMode();
  window.console.log = (): void => {};
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideRouter(routes, withComponentInputBinding()),
    provideIonicAngular(),
    provideHttpClient(withJsonpSupport()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translationLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: usersServiceFactory,
      deps: [UsersService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => (): void => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ]
}).catch(err => console.error(err));
