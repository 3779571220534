import { Device } from '@capacitor/device';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../users/users.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly defaultLanguage = 'en';
  private readonly validLanguages = ['en', 'de'];
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly usersService: UsersService = inject(UsersService);

  /*
   * Will set the language to the device language if available, otherwise to the default language
   * In case user is logged in, the language will be set to the user's language
   */
  public async initialize(): Promise<void> {
    this.translate.setDefaultLang(this.defaultLanguage);
    this.translate.addLangs(this.validLanguages);
    // get language from device
    const { value } = await Device.getLanguageCode();
    // if available, use it, otherwise use default language
    let lang = value ? value : this.defaultLanguage;
    if (await this.usersService.isAuthenticated()) {
      const currentUser = this.usersService.entity.value;

      if (currentUser && currentUser.settings && currentUser.settings?.language) {
        lang = currentUser.settings.language;
      }
    }

    const langToUse = this.validLanguages.includes(lang) ? lang : this.defaultLanguage;
    await firstValueFrom(this.translate.use(langToUse));
  }
}
