import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth/auth.guard';
import { firebaseLinksGuard } from './shared/guards/firebase-links/firebase-links.guard';
import { introGuard } from './shared/guards/intro/intro.guard';
import { nonAuthGuard } from '@shared/guards/non-auth/non-auth.guard';
import { setupGuard } from './shared/guards/setup/setup.guard';

export const routes: Routes = [
  {
    path: 'intro',
    loadComponent: () => import('./pages/intro/intro.page').then(m => m.IntroPage),
    canActivate: [introGuard]
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage),
    canLoad: [nonAuthGuard],
    canActivate: [nonAuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.routes').then(m => m.routes),
    canLoad: [nonAuthGuard],
    canActivate: [nonAuthGuard]
  },
  {
    path: 'lostpw',
    loadComponent: () => import('./pages/lostpw/lostpw.page').then(m => m.LostPwPage),
    canLoad: [nonAuthGuard],
    canActivate: [nonAuthGuard]
  },
  // used as fallback for email links, if app link service can not handle it
  {
    path: 'emails',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage),
    canActivate: [firebaseLinksGuard]
  },
  {
    path: 'setpw',
    loadComponent: () => import('./pages/setpw/setpw.page').then(m => m.SetPwPage),
    canLoad: [nonAuthGuard],
    canActivate: [nonAuthGuard]
  },
  {
    path: 'setup',
    loadChildren: () => import('./pages/setup/setup.routes').then(m => m.routes),
    canActivate: [setupGuard],
    canLoad: [setupGuard]
  },
  {
    path: 'verify-email',
    loadComponent: () => import('./pages/verify-email/verify-email.page').then(m => m.VerifyEmailPage)
  },
  {
    path: 'import',
    loadComponent: () => import('./pages/import/import.page').then(m => m.ImportPage),
    canActivate: [authGuard],
    canLoad: [authGuard]
  },
  {
    path: 'public',
    loadChildren: () => import('./pages/public/public.routes').then(m => m.routes)
  },
  {
    path: 'developer',
    loadComponent: () => import('./pages/developer/developer.page').then(m => m.DeveloperPage)
  },
  // be careful not to mix up route order protected route should be loaded last
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.routes').then(m => m.routes),
    canActivate: [authGuard],
    canLoad: [authGuard]
  }
];
